* {
    box-sizing: border-box;
}

.chargezen_modal_container {
    background: #000;
}

.chargezen_modal_container .logged_out_state_container {
    border-radius: 20px;
    color: rgb(0, 0, 0);
    font-family: Poppins, sans-serif;
    overflow: initial;
    -webkit-box-align: stretch;
    align-items: stretch;
    max-height: calc(100vh - 138px);
    width: 100%;
    flex-direction: column;
    padding: 0px;
    max-width: 997px;
    background: rgb(255, 255, 255) !important;
    box-shadow: none !important;
    display: flex !important;
    margin: 0 auto;
}

.chargezen_modal_container>.logged_out_state_container .logged_out_state_wrapper {
    display: flex;
    position: relative;
    height: 100%;
    -webkit-box-pack: inherit;
    justify-content: inherit;
    -webkit-box-align: inherit;
    align-items: inherit;
}

.chargezen_modal_container>.logged_out_state_container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .right_section_wrapper {
    width: 100%;
    max-width: 45.5%;
    background: rgb(42, 42, 42);
    border-radius: 0px 10px 10px 0px;
    overflow-y: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    min-height: 100%;
    align-items: center;
    justify-content: center;
    left: 1px;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper {
    width: 100%;
    max-width: 54.5%;
}

.logo-top-left {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    filter: drop-shadow(rgba(255, 255, 255, 0.9) 0px 0px 5px) drop-shadow(rgba(0, 0, 0, 0.1) 0px 3px 5px) drop-shadow(rgba(0, 0, 0, 0.06) 0px 1px 18px) drop-shadow(rgba(0, 0, 0, 0.08) 0px 6px 10px);
    z-index: 555;
    background-color: rgb(252, 137, 226);
    overflow: hidden;
    left: calc(50% - 225px) !important;
}


.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper>.logo-top-left>img {
    width: 100%;
    height: 100%;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper h1.header_main {
    font-size: 40px;
    color: black;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    margin: 0 0 16px;
    width: 100%;
    text-align: left;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper h2.sub_header_main {
    width: 100%;
    text-align: left;
    font-size: 28px;
    color: #fff;
    font-family: Poppins, sans-serif;
    margin-bottom: 1rem;
    font-weight: 600 !important;
    line-height: 1 !important;
    margin: 0 0 10px;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper p.description_text {
    margin: 0;
    width: 100%;
    text-align: left;
    display: block;
    font-weight: 400;
    font-size: 14px;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
    margin-top: 0px;
    line-height: 20px !important;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper .cta-button {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
    row-gap: 15px;
    margin-top: 35px;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper.cta-button button.cstm-btn {
    padding: 5px 25px;
    color: black;
    background: white;
    border: 1px solid rgb(204, 204, 204);
    transition: border-color 0.15s ease-in-out 0s, color 0.15s ease-in-out 0s;
    min-width: 230px;
    max-width: 230px;
    min-height: 45px;
    border-radius: 100px;
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper .cta-button button.cstm-btn>img {
    max-width: 22px !important;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper .cta-button button.cstm-btn.btn-black {
    background: black;
    color: white;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper .cta-button button.cstm-btn.btn-black span {
    line-height: normal;
    display: block;
    position: relative;
    top: 1px;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper .cta-button>button.cstm-btn {
    padding: 5px 25px;
    min-width: 230px;
    max-width: 230px;
    min-height: 45px;
    border-radius: 100px;
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;
    line-height: 1;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    border: 0;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper .cta-button>button.cstm-btn {
    color: black;
    background: white;
    border: 1px solid rgb(204, 204, 204);
    transition: border-color 0.15s ease-in-out 0s, color 0.15s ease-in-out 0s;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .right_section_wrapper>h3.redeem-text {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.335em;
    color: #5c5f62;
    text-align: center;
    margin-left: 1.335em;
    position: unset;
    position: relative;
    margin-top: -15px;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .right_section_wrapper>.mobile-case-upper {
    min-width: min(300px, 100vw);
    background-color: #fff;
    border-radius: 30px;
    padding: 2px;
    position: absolute !important;
    bottom: 16.7%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 72%;
    max-width: 350px;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .right_section_wrapper>.mobile-case-upper>.member-pass-container-modal-preview {
    min-width: min(300px, 100vw);
    height: 100%;
    background: gray;
    border-radius: 30px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 35px 10px 0;
    max-width: 350px;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .right_section_wrapper>.mobile-case-upper>.network-icon {
    z-index: 1;
    max-width: 100%;
    position: absolute;
    top: calc(3% + 2px);
    right: calc(6% + 2px);
    width: 45px;
}

.chargezen_modal_container {
    background: #000;
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .right_section_wrapper>.mobile-case-upper>.member-pass-container-modal-preview>.mobile_wrapper {
    background-color: rgb(252, 137, 226);
    max-height: 100%;
    overflow: hidden auto;
    flex: 1 1 min(50% - 10px, 100vw);
    border-radius: 10px 10px 0px 0px;
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 11px 15px) drop-shadow(rgba(0, 0, 0, 0.06) 0px 9px 46px) drop-shadow(rgba(0, 0, 0, 0.04) 0px 24px 38px);
}


.chargezen_modal_container .logged_out_state_container>.body-model-wrap {
    padding: 0px;
    margin-top: 0px;
    min-width: 295px;
    height: 558px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    overflow: hidden !important;
    max-height: 100% !important;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper>.content-max-width {
    padding-left: 10px;
    padding-top: 73px;
    padding-right: 10px;
    max-width: 440px;
}

.chargezen_modal_container .logged_out_state_container {
    position: relative;
}

.pass-logo {
    display: flex;
    position: relative;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    column-gap: 10px;
    padding: 10px;
    border-bottom: 1px solid rgb(149, 149, 149);
}

.pass-logo>img {
    width: 100%;
    max-width: 33px;
    height: 33px;
    border-radius: 100%;
}

.pass-logo .logo-name {
    color: rgb(0, 0, 0) !important;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
}

.Mobile_upper .passname {
    font-weight: 400;
    font-size: 22px;
    line-height: 40px;
    word-break: break-word;
    padding: 0 10px;
    text-align: left;
    color: rgb(0, 0, 0) !important;
}

.Mobile_upper .Points-div-wrapper {
    display: flex;
    justify-content: space-between;
    color: rgb(0, 0, 0) !important;
    position: relative;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0 10px 10px;
    text-align: left;
}

.mobile_header_wrapper {
    width: 100%;
}

.Mobile_upper .Points-div-wrapper div {
    display: flex;
    flex-flow: column;
}

.Mobile_upper .Points-div-wrapper div span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    word-break: break-word;
    font-family: "Open Sans", sans-serif;
    color: rgb(0, 0, 0) !important;
    min-height: 24px;
}


.mobile_wrapper .qr-code-wrap>.code-containr img {
    padding: 0;
    background: white;
    border-radius: 4px;
    -webkit-box-align: center;
    max-width: 98px;
    display: block;
    margin: 0 auto;
}

.mobile_lower span {
    color: rgb(0, 0, 0) !important;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
}

.mobile_lower .botom-footer-image {
    padding: 10px 0 0;
}

.mobile_lower .botom-footer-image>img {
    max-height: 101px;
    width: 100%;
}

.mobile_simulator_screen_container {
    background: url('../../../public/assets/images/mobileSimulator.png') no-repeat 50%;
    position: relative !important
}

.mobile_simulator_wrapper>.mobile_simulator_screen_container {
    min-height: 568px;
    background-position: top;
}

.mobile_simulator_wrapper>.mobile_simulator_screen_container>.screen_main_container {
    border-radius: .3125rem;
    color: #29302c;
    display: flex;
    flex-direction: column;
    height: 245px;
    left: 50%;
    margin-left: 0;
    position: absolute;
    top: 61px;
    width: 165px;
    transform: translateX(-50%);
    background-color: #e33434
}

.mobile_header_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 7px;
    align-items: center
}

.chargezen_modal_container>.logged_out_state_container .logged_out_state_wrapper {
    width: 100%;
}


.mobile_simulator_wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.mobile_simulator_wrapper>.mobile_simulator_screen_container {
    width: 100%;
}

.mobile_header_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 7px;
    align-items: center
}

.mobile_header_wrapper .left_section.flex_wrapper {
    display: flex;
    align-items: flex-end;
}

.logo_wrapper {
    margin: 3px 10px 0 0px;
}

.mobile_header_wrapper .left_section.flex_wrapper img {
    width: 35px;
    height: 35px;
    object-fit: cover;
}

.mobile_header_wrapper .right_section {
    background: transparent;
    text-align: right;
    color: #fff;
}

.mobile_header_wrapper .right_section .balance_title {
    font-size: 10px;
    line-height: normal;
    margin-top: 0;
}

.mobile_header_wrapper .right_section .balance_wrapper .balanace_container {
    font-size: 11px;
    font-weight: 600;
    font-family: Avenir;
}

.mobile_banner_wrapper img {
    max-height: 110px;
    object-fit: cover;
}

.mobile_scanner_wrapper {
    max-width: 50%;
    margin: 43px auto 10px;
    display: block;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .right_section_wrapper .box-footer-mobile button.border-transparent {
    display: inline-flex;
    border: 2px solid transparent;
    border-radius: 100px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    width: 120px;
    color: #fff;
    align-items: center;
    background: transparent;
    cursor: pointer;
    padding: 7px 25px;
    outline: 1px solid gray;
    gap: 10px;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .right_section_wrapper .box-footer-mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    gap: 20px;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .right_section_wrapper .box-footer-mobile button.border-transparent>img {
    max-width: 15px !important;
}

.claim-reward.hiddn-md {
    display: none !important;
}

.logged_in_state_wrapper .left_section_wrapper .content-max-width,
.logged_in_state_wrapper .left_section_wrapper .content-max-width * {
    text-align: left !important;
    align-items: flex-start !important;
}

.logged_in_state_wrapper .left_section_wrapper .content-max-width h2.sub_header_main {
    text-align: left !important;
    color: #fff !important;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper>.content-max-width {
    padding-right: 0;
    max-width: 500px !important;
    width: 100%;
    padding: 100px 20px 20px !important;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper p.description_text {
    text-align: left !important;
    color: #fff !important;
}

.chargezen_modal_container .logged_out_state_container {
    background: transparent !important;
}

.logo-top-left {
    transform: none;
    left: 35px !important;
    top: 40px;
}

.mobile_header_wrapper {
    padding-bottom: 0;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper .qr_code_container {
    background: #fff;
    margin: 20px 0 0;
}

.chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .right_section_wrapper {
    background: transparent !important;
}



.chargezen_modal_container .logged_out_state_container {
    max-height: unset !important;
    min-height: 100%;
}

.App {
    overflow: hidden;
}

.chargezen_modal_container>.logged_out_state_container .logged_out_state_wrapper {
    align-items: center;
}

.mobile_simulator_wrapper>.mobile_simulator_screen_container>.screen_main_container {
    width: 254px;
    height: 330px;
}

.mobile_scanner_wrapper {
    margin: auto;
    margin-bottom: 5px;
}

.mobile_header_wrapper .left_section.flex_wrapper {
    width: 100%;
    max-width: 85px;
    align-items: center;
}



.mobile_simulator_wrapper>.mobile_nav {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    top: 40px;
    width: 100%;
    max-width: 50%;
}



.logged_in_state_wrapper .left_section_wrapper .content-max-width {
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 10px;
}

.logged_in_state_wrapper .left_section_wrapper .content-max-width h2.sub_header_main {
    text-align: left !important;
    font-size: 28px !important;
}

.logged_in_state_wrapper .left_section_wrapper .content-max-width p.description_text {
    text-align: center !important;
    font-size: 14px !important;
}

.logged_in_state_wrapper .left_section_wrapper .content-max-width .qr_code_container {
    border: 2px solid #000;
    border-radius: 4px;
    padding: 8px;
    max-width: 148px !important;
    max-height: 148px !important;
}

.logged_in_state_wrapper .left_section_wrapper .content-max-width .qr_code_container svg {
    height: 100% !important;
    width: 100% !important;
}

.chargezen_modal_container .logged_out_state_container>.body-model-wrap {
    height: auto !important;
}

h3.welcome_back_message_wrapper {
    color: #fff;
    font-weight: 200;
}

h3.welcome_back_message_wrapper span.customer_name_wrapper {
    font-weight: 900;
}

.get_pass_form_container .text_fields {
    border: 1px solid #fff;
    border-radius: 5px;
}

.get_pass_form_container .pass_form_control {
    display: flex;
    gap: 10px;
    margin-top: 14px;
}

.get_pass_form_container {
    display: flex;
    flex-flow: column;
    gap: 15px;
}

.get_pass_form_container .pass_form_control {
    display: flex;
    gap: 15px;
}

.get_pass_form_container .pass_form_control label {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-flow: column;
}

.get_pass_form_container .pass_form_control label input {
    padding: 12px;
}

.get_pass_form_container .pass_form_control label span {
    color: #fff;
}

button.get_pass_button {
    border: 1px solid #fff;
    padding: 12px;
    border-radius: 5px;
    background: transparent;
    color: #fff;
    display: flex;
    justify-content: center;
}

button.get_pass_button:hover {
    background: #fff;
    color: #000;
}

.loading_spinner_container {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.something_went_wrong {
    background: #c84b4b;
    color: #e5dede;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #a30000;
}

@media(max-width:991px) {

    .chargezen_modal_container>.logged_out_state_container .logged_out_state_wrapper {
        padding: 0 15px;
    }


}

@media(max-width:767px) {

    .chargezen_modal_container>.logged_out_state_container .logged_out_state_wrapper {
        flex-flow: column-reverse;
    }

    .chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper {
        width: 100%;
        max-width: 100%;
    }

    .chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .right_section_wrapper {
        max-width: 100%;
    }

    .chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper>.content-max-width {
        padding: 29px 20px 20px !important;
    }

    .logo-top-left {
        display: none;
    }

    .chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .right_section_wrapper .box-footer-mobile {
        display: none;
    }

    .chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper .qr_code_container {
        margin: 20px auto 0;
    }

    .chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper h2.sub_header_main,
    .chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper p {
        text-align: center !important;
    }

    .chargezen_modal_container .logged_out_state_container .logged_out_state_wrapper .left_section_wrapper p.description_text {
        text-align: center !important;
    }

    h3.welcome_back_message_wrapper {
        margin: 0 auto;
    }

    .mobile_simulator_wrapper>.mobile_nav {
        max-width: 70%;
    }

}